import clsx from 'clsx';
import { useRouter } from 'next/router';
import Link from 'next/link';
import styled from 'styled-components';
import * as dayjs from 'dayjs';
import * as relativeTime from 'dayjs/plugin/relativeTime';
import {
  Badge,
  Button,
  ButtonGroup,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  UncontrolledDropdown
} from 'reactstrap';
import { PrismicRichText, PrismicLink } from '@prismicio/react';
import { PrismicNextImage } from '@prismicio/next';
import { Heading } from '../../components/Heading';
import { BaseSlice } from '../../components/BaseSlice';
import { Bounded } from '../../components/Bounded';
import { RichText } from '../../components/RichText';
import DocumentIcon from '../../components/DocumentIcon';
import * as prismicH from '@prismicio/helpers';
import { linkResolver } from '../../prismicio';
import LANGUAGES from '../../lib/languages';
import {
  humanSize,
  versions,
  versionsByLang,
  documentLink
} from '../../lib/publications';

dayjs.extend(relativeTime);

/**
 * @typedef {import("@prismicio/client").Content.ListSlice} ListSlice
 * @typedef {import("@prismicio/react").SliceComponentProps<ListSlice>} ListProps
 * @param { ListProps }
 */
function List({ slice, index }) {
  const hasCTA = prismicH.isFilled.link(slice.primary?.buttonLink);
  const router = useRouter();
  const navigate = () => {
    if (!hasCTA) return;
    router.push(linkResolver(slice.primary?.buttonLink));
  };

  // return null when there's no content in the list
  if (!(slice.items?.length || slice._items?.length)) {
    return null;
  }

  return (
    <Bounded as="section" index={index}>
      <BaseSlice>
        <div
          className={clsx(
            slice.primary?.center && 'text-center',
            prismicH.isFilled.richText(slice.primary?.text) && 'mb-5'
          )}>
          <RichText field={slice.primary?.text} />
        </div>

        <div
          className={clsx(
            'row',
            slice.variation === 'withImages' &&
              slice.primary?.verticallyCenterImages &&
              'align-items-center'
          )}>
          {slice.variation === 'withLatestContent' && (
            <LatestOrRelatedItems items={slice._items} />
          )}
          {slice.variation === 'withLatestSelection' && (
            <LatestOrRelatedItems items={slice._items} />
          )}
          {slice.variation === 'withRelatedContent' &&
            slice.primary?.related === 'project' && (
              <LatestOrRelatedItems items={slice._items} />
            )}
          {slice.variation === 'withRelatedContent' &&
            slice.primary?.related === 'publication' && (
              <PublicationItems items={slice._items} />
            )}

          {slice.variation === 'default' &&
            slice.items?.map((item, idx) => (
              <div
                key={idx}
                className={`mb-4 col-lg-${parseInt(
                  12 / (slice.primary.numItemsPerRow || 3)
                )}`}>
                <div className="mb-3">
                  {prismicH.isFilled.image(item.image) &&
                    (prismicH.isFilled.link(item.link) ? (
                      <PrismicLink className="text-reset" field={item.link}>
                        <Img item={item.image} />
                      </PrismicLink>
                    ) : (
                      <Img item={item.image} />
                    ))}
                </div>
                <Heading as="h5">
                  {prismicH.isFilled.link(item.link) ? (
                    <PrismicLink className="text-reset" field={item.link}>
                      {item.label}
                    </PrismicLink>
                  ) : (
                    item.label
                  )}
                </Heading>
                <PrismicRichText field={item.description} />
              </div>
            ))}

          {slice.variation === 'withImages' &&
            slice.items?.map((item, idx) => (
              <div
                key={idx}
                className="mb-4 col-lg-2 col-md-4 col-sm-6 text-center">
                <div className="mb-2">
                  <PrismicNextImage
                    field={item.image}
                    alt={item.image.alt}
                    sizes="100vw"
                    onClick={navigate}
                    className={clsx(
                      'img-fluid rounded',
                      hasCTA && 'cursor-pointer'
                    )}
                  />
                </div>
                <strong>{item.label}</strong>
                <PrismicRichText field={item.description} />
              </div>
            ))}
        </div>

        {slice.variation === 'default' && hasCTA && (
          <div className={clsx('mt-3', slice.primary.center && 'text-center')}>
            <PrismicLink
              className="btn btn-outline-primary px-4"
              field={slice.primary.buttonLink}>
              {slice.primary.buttonText}
            </PrismicLink>
          </div>
        )}
        {(slice.variation === 'withLatestContent' ||
          slice.variation === 'withLatestContentSelection') &&
          slice.primary?.buttonTag &&
          slice.primary?.buttonText && (
            <div
              className={clsx('mt-3', slice.primary.center && 'text-center')}>
              <Link
                className="btn btn-primary px-4"
                href={searchLink(slice.primary.buttonTag)}>
                {slice.primary.buttonText}
              </Link>
            </div>
          )}
      </BaseSlice>
    </Bounded>
  );
}

export default List;

export function LatestOrRelatedItems({ items, className, ...props }) {
  return items?.map((item, i) => (
    <LatestOrRelatedItem
      item={item}
      key={item.id}
      // show a maximum of 4x2 items (desktop), 2x3 items (tablet), 1x4 items (phone)
      className={clsx(
        className,
        i >= 4 && 'd-sm-none',
        i >= 6 ? 'd-lg-block' : i >= 4 ? 'd-md-block' : null
      )}
      {...props}
    />
  ));
}

export function LatestOrRelatedItem({ item, className, ...props }) {
  const tag =
    (item.data.tags || [])[0]?.tag?.data?.name?.toLowerCase() || item.type;
  console.log(item.uid, tag);
  const pub_date =
    item.data.publication_date && dayjs(dayjs(item.data.publication_date));
  return (
    <div className={clsx('col-md-6 col-lg-3 mb-4', className)} {...props}>
      <div className="mb-3">
        {prismicH.isFilled.image(item.data.listImage) && (
          <PrismicLink className="text-reset" document={item}>
            <Img item={item.data.listImage} />
          </PrismicLink>
        )}
      </div>
      <Badge className="fw-normal mb-3" color="ship-light">
        {tag}
      </Badge>
      <Heading as="h5" className="mb-2">
        <PrismicLink className="text-reset" document={item}>
          {prismicH.asText(item.data.title)}
        </PrismicLink>
      </Heading>
      <RichText field={item.data.listDescription} mb={2} />
      {pub_date && (
        <div className="text-muted small" title={pub_date.format('YYYY-MM-DD')}>
          {pub_date.from()}
        </div>
      )}
    </div>
  );
}

export function PublicationItems({ items, ...props }) {
  return items?.map((item) => (
    <PublicationItem item={item} key={item.id} {...props} />
  ));
}

export function PublicationItem({ item, ...props }) {
  // when present, the specified version is shown
  // (the item's documents are already filtered)
  const useVersion = item._useVersion;

  return (
    <div className="mb-3" {...props}>
      <div className="text-reset d-flex align-items-center" document={item}>
        <div className="p-2">
          <DocumentIcon style={{ width: 78, height: 110 }} />
        </div>
        <div className="p-2 ps-4 flex-grow-1">
          {item.data.tags?.length > 0 && (
            <Badge className="fw-normal mb-2" color="ship-light">
              {item.data.tags[0].tag.data.name.toLowerCase()}
            </Badge>
          )}
          <Heading as="h5" className="mt-1">
            {prismicH.asText(item.data.title)}
            {useVersion && ` (version ${useVersion})`}
          </Heading>
          {item.data.subtitle && (
            <div className="mb-1">{item.data.subtitle}</div>
          )}
          <div className="py-2 d-flex align-items-center">
            <PublicationItemDocuments
              uid={item.uid}
              useVersion={useVersion}
              documents={item.data.documents}
            />
            {item.data.year && (
              <div className="ps-2 text-muted small">
                {dayjs(dayjs(item.data.year)).format('D MMMM YYYY')}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

function PublicationItemDocuments({ uid, useVersion, documents, ...rest }) {
  const docsByLang = versionsByLang(documents);
  const latestVersion = versions(documents)[0];
  const hasVersions =
    Object.values(docsByLang).find((d) => d.length > 1) !== undefined;

  return (
    <div {...rest}>
      {Object.entries(docsByLang).map(([lang, docs]) => (
        <ButtonGroup size="sm" className="me-2" key={lang}>
          <Button outline href={documentLink(uid, docs[0], !!useVersion)}>
            {LANGUAGES[lang]?.flag} {LANGUAGES[lang]?.name || 'Download'} (
            {hasVersions &&
              docs[0].version !== latestVersion && // show version only if not the latest
              `v${docs[0].version}, `}
            {humanSize(docs[0].doc.size)})
          </Button>
          {hasVersions && (
            <ButtonGroup>
              <UncontrolledDropdown>
                <DropdownToggle outline size="sm" caret />
                <DropdownMenu right>
                  {docs.map((doc) => (
                    <DropdownItem
                      href={documentLink(uid, doc, true)}
                      key={doc.version}>
                      {LANGUAGES[lang]?.flag} version {doc.version}
                    </DropdownItem>
                  ))}
                </DropdownMenu>
              </UncontrolledDropdown>
            </ButtonGroup>
          )}
        </ButtonGroup>
      ))}
    </div>
  );
}

function Img({ item, ...props }) {
  return (
    <div className="position-relative" style={{ height: 250 }}>
      <StyledImage
        field={item}
        fill
        sizes="(max-width: 768px) 100vw,
              (max-width: 1200px) 50vw,
              33vw"
        alt={item.alt}
        className="img-fluid rounded"
        {...props}
      />
    </div>
  );
}

const StyledImage = styled(PrismicNextImage)`
  object-fit: cover;
  @media (max-width: 992px) {
    object-fit: cover !important;
  }
`;

function searchLink(tag) {
  if (tag?.data?.name) {
    return `/search?type=news&tags=${tag.data.name}`;
  } else {
    return '/search?type=news';
  }
}
